// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-theme-courses-src-templates-course-page-js": () => import("/opt/build/repo/gatsby-theme-courses/src/templates/coursePage.js" /* webpackChunkName: "component---gatsby-theme-courses-src-templates-course-page-js" */),
  "component---gatsby-theme-courses-src-templates-lesson-page-js": () => import("/opt/build/repo/gatsby-theme-courses/src/templates/lessonPage.js" /* webpackChunkName: "component---gatsby-theme-courses-src-templates-lesson-page-js" */),
  "component---gatsby-theme-courses-src-templates-courses-page-js": () => import("/opt/build/repo/gatsby-theme-courses/src/templates/coursesPage.js" /* webpackChunkName: "component---gatsby-theme-courses-src-templates-courses-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/demo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

